@mixin mediaBreak($args...) {
    @if length($args) == 1 {
        @media (min-width: nth($args, 1)) {
            @content
        }
    };
    @if length($args) == 2 {
        @media (min-width: nth($args, 1)) and (max-width: nth($args, 2)) {
            @content
        }
    };
}

@mixin mediaBreakEndPoint($args...) {
    @media (max-width: nth($args, 1)) {
        @content
    }
}

@mixin mediaPointerFine() {
    @media (pointer: fine) {
        @content
    }
}

@mixin mediaPointerCoarse() {
    @media (pointer: coarse) {
        @content
    }
}