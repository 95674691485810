@import '../variables';
@import '../mixins';

.select-options-preview-modal {
    .modal-dialog {
        @include mediaBreakEndPoint(575.98px) {
            margin-left: 4rem;
            margin-right: 4rem;
        }
    }
    .modal-content {
        margin: 3rem 0;
    }
    .modal-body {
        padding: 0.5rem 0;
    }
    .select-title-bar {
        padding: 0.5rem 1.25rem;
        margin: 0.35rem 0 0.75rem;
    }
    // .select-title {
    // }
    .option-item {
        padding: 0.85rem 1.25rem;
        cursor: pointer;
        &:hover {
            // background-color: rgba($color: #000, $alpha: 0.15);
            background-color: rgba(165, 165, 167, 0.1);
        }
        &.is-selected {
            // background-color: rgba($color: #000, $alpha: 0.3);
            background-color: rgba(136, 137, 139, 0.33);
        }
    }
}
