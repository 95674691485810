@import 'variables';
@import 'mixins';

:root {
    --suspended-col: #{$yellow};
    --suspended-rev-col: #{$white};
    --doing-col: #{$green-dark};
    --doing-rev-col: #{$white};
    // --done-col: #{$blue};
    --done-col: #{$gray-400};
    --done-rev-col: #{$white};
    --rejected-col: #{$red};
    --rejected-rev-col: #{$white};
}

.main-content-wrap {
    min-height: calc(100vh - 70px - 0.67rem);
}

.heading-1 {
    font-size: 1.67rem;
}
.heading-2 {
    font-size: 1.33rem;
}
.heading-3 {
    font-size: 1.17rem;
}

.text-small {
    font-size: 0.833rem;
}

.fw-medium {
    font-weight: 500;
}


i {
    font-family: 'FontAwesome';
  font-style: normal;
}


.clr {
    clear: both;
}

.clearfix::after,
.clearfix::before {
    content: "";
    display: block;
    display: table
}

.clearfix::after {
    clear: both
}

.container-limited {
    max-width: 1625px;
}



.fs-6 {
    font-size: 1.05rem !important;
}

.fs-normal {
    font-size: 1rem !important;
}


img {
    max-width: 100%;
}

.broken-image-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #fbfbfb;
    background-color: #fcfcfc;
}

input {
    outline: none;
}

textarea {
    line-height: 1.88 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

::-webkit-input-placeholder {
    color: $gray-500 !important;
}
::-moz-placeholder {
    color: $gray-500 !important;
}
:-moz-placeholder {
    color: $gray-500 !important;
}
:-ms-input-placeholder {
    color: $gray-500 !important;
}
::-ms-input-placeholder {
    color: $gray-500 !important;
}
::placeholder {
    color: $gray-500 !important;
}

.custom-search-input-remove-btn {
    display: none;
    position: absolute;
    right: 14px;
    bottom: 13px;
    margin-top: 7px;
    margin-left: -19px;
    z-index: 100;
    cursor: pointer;
    font-size: 18px;
}


label {
    margin-bottom: 0.33rem;
}

ul {
    list-style: none;
    padding: 0;
}

.table-rounded {
    border-collapse: separate;
    border-radius: 6px;
    border-spacing: 0px;
    &.table-group-divider,
    .table-group-divider {
        box-shadow: inset 0 1px 0 0 currentColor, 0 -1px 0 0 currentColor;
    }
    &.table-bordered {
        border: 1px solid #dee2e6;
        td,
        th {
            border-left: none;
            border-top: solid #dee2e6 1px;
            vertical-align: middle;
        }
        th {
            border-top: none;
        }
        // td:first-child,
        // th:first-child {
        //     border-left: none;
        // }
        td:last-child,
        th:last-child {
            border-right: none;
        }
        tbody:first-child {
            tr:first-child {
                td {
                    border-top-width: 0;
                }
            }
        }
    }
    th {
        &:first-child,
        &:last-child {
            overflow: hidden;
        }
        &:first-child {
            border-radius: 6px 0 0 0;
        }
        &:last-child {
            border-radius: 0 6px 0 0;
        }
    }
    tbody {
        tr:last-child {
            td {
                &:first-child,
                &:last-child {
                    overflow: hidden;
                }
                &:first-child {
                    border-radius: 0 0 0 6px;
                }
                &:last-child {
                    border-radius: 0 0 6px 0;
                }
            }
        }
    }
}

a {
    color: inherit;
    &:hover {
        text-decoration: none;
    }
}

.icon {
    svg {
        width: 1em;
        height: auto;
    }
}

.custom-toast-icon {
    font-size: 1.667rem;
    svg {
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .modal-dialog.modal-lg {
        max-width: 650px;
    }
}


.btn {
    border-radius: $card-border-radius;
    padding: 0.61rem 1.3rem;
}

.btn-lg, 
.btn-group-lg > .btn {
    padding: 0.92rem 1.95rem;
    font-size: 1.17rem;
}

.btn-info {
    background-color: $blue;
    border-color: $blue;
    &:hover {
        background-color: #03a6d8;
        border-color: #109bc5;
    }
    &:focus,
    &.focus {
        background-color: #03a6d8;
        border-color: #109bc5;
        box-shadow: 0 0 0 0.2rem rgba(58, 207, 252, 0.5);
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: #109bc5;
        border-color: #1792b9;
    }
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 207, 252, 0.5);
    }
}

.btn-outline-info {
    color: $blue;
    border-color: $blue;
    &:hover {
        background-color: $blue;
        border-color: $blue;
    }
    &:focus,
    &.focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 207, 252, 0.5);
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: $blue;
        border-color: $blue;
    }
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 207, 252, 0.5);
    }
}

.btn-gray {
    background-color: $bg-color;
    // border-color: $bg-color;
    // border-color: #e3e5e6;
    // border-color: #eeefef;
    // border-color: #eef1f1;
    border-color: #eff3f5;
    // box-shadow: $boxes-shadow;
    // box-shadow: 0 0 3px 0 rgb(15 23 19 / 9%);
    &:focus,
    &.focus {
        // box-shadow: 0 0 2px 1px rgba(15, 23, 19, 0.09);
        box-shadow: 0 0 2px 1px rgba(135, 161, 148, 0.09);;
    }
    &:focus-visible {
        // background-color: #f1f1f5;
        // border-color: #f1f1f5;
        background-color: #efefef;
        border-color: #efefef;
    }
    &:hover {
        // background-color: #e0e0e7;
        // background-color: #f1f1f5;
        background-color: #efefef;
        // border-color: #f1f1f5;
        // border-color: #d9e0e3;
        border-color: #e6ecf0;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        // background-color: #d9d9df;
        // background-color: #efefef;
        background-color: #dfdfdf;
        // border-color: #d9d9df;
        // border-color: #cbcbe1;
        // border-color: #d9d9df;
        // border-color: #e3e3e3;
        border-color: #d7d7d7;
        // &:focus {
        //     // 
        // }
    }
}

.show > .btn-gray.dropdown-toggle {
    // background-color: #d9d9df;
    background-color: #efefef;
    // border-color: #d9d9df;
    // border-color: #cbcbe1;
    // border-color: #d9d9df;
    border-color: #e3e3e3;
    // &:focus {
    //     // 
    // }
}

.btn-dark {
    background-color: $primary;
    border-color: $primary;
    color: $gray-blue-light;

    &:focus {
        box-shadow: 0 0 3px 1px rgba(88, 88, 88, 0.35);
    }
    &:focus-visible {
        background-color: #49515a;
        border-color: #49515a;
        box-shadow: 0 0 3px 1px rgba(88, 88, 88, 0.35);
    }
    &:hover {
        background-color: #49515a;
        border-color: #49515a;
    }
    &:not(:disabled):not(.disabled):active {
        background-color: #5d5e66;
        border-color: #646566;
    }
}

.btn-outline-dark {
    border-color: $primary;
    color: $primary;

    &:focus {
        box-shadow: 0 0 2px 1px rgb(25 25 25 / 10%);
    }
    // &:focus-visible {
    //     background-color: #f1f1f5;
    //     border-color: #f1f1f5;
    // }
    &:hover {
        background-color: $primary;
        border-color: $primary;
    }
    &:not(:disabled):not(.disabled):active {
        background-color: $primary;
        border-color: $primary;
    }
}

.btn-green {
    background-color: #2bbb78 !important;
    border-color: #2bbb78 !important;
    color: $white !important;
    &:focus {
        box-shadow: 0 0 3px 3px rgba(135, 217, 179, 0.35);
    }
    &:focus-visible {
        background-color: #19a966 !important;
        border-color: #19a966 !important;
        box-shadow: 0 0 3px 3px rgba(135, 217, 179, 0.35);
    }
    &:hover {
        background-color: #19a966 !important;
        border-color: #19a966 !important;
        color: $white !important;
    }
    &:not(:disabled):not(.disabled):active {
        background-color: #0b9353 !important;
        border-color: #0b9353 !important;
        color: $white !important;
    }
}


.step-item-content .select__control {
    border-color: $border-color;
}
.step-item-content .select__menu {
    margin-top: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 999;
    box-shadow:
        0 0 1px 1px #b5b5b5,
        0 3px 6px 1px rgb(110, 110, 110, 0.35);
}
.step-item-content .select__control--menu-is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.step-item-content .select__indicator {
    font-size: 1.12em;
    padding: 0.9rem 0.67rem;
    height: calc(1.5em + 1.7rem) !important;
}
.step-item-content .select__clear-indicator {
    padding: 4px;
    margin: 4px;
    cursor: pointer;
}


.form-group {
    margin-bottom: 1.33rem;
}
.form-regular {
    .btn,
    .form-control {
        border-radius: $border-radius-small !important;
    }
}
.form-compact {
    .form-group {
        margin-bottom: 0.75rem;
    }
    .form-control {
        // padding: 0.5rem 0.67rem !important;
        padding: 0.5rem 0.67rem;
        height: calc(1.15em + 1.67rem + 2px) !important;
    }
    .form-control.select2-selection--multiple {
        min-height: calc(1.15em + 1.67rem + 2px) !important;
    }
    textarea.form-control {
        height: auto !important;
    }
}
.validate-error-tooltip {
    transform: none !important;
}

.form-control {
    font-size: 1.075rem;
    // padding: 0.75rem 0.67rem;
    // height: calc(1.15em + 2.17rem + 2px) !important;
    padding: 0.9rem 0.67rem;
    height: calc(1.5em + 1.8rem + 2px) !important;
    border-radius: $card-border-radius !important;
    &.is-invalid {
        // border-color: $red !important;
        border-color: #E82646 !important;
        color: #E82646 !important;
        &.has-validation-error {
            background-image: none;
        }
    }
    &:disabled {
        background-color: #fbfbfb;
        color: #bdbdbd !important;
    }
    &:focus {
        box-shadow: none !important;
    }
    &.focused:not(:disabled):not(.is-valid):not(.is-invalid) {
        border-color: #80bdff !important;
    }
    &:not(.is-valid):not(.is-invalid) {
        color: inherit;
        border-color: $border-color;
        &:focus {
            border-color: #80bdff !important;
        }
    }
    // &.slctd-opt-txt {
    //     padding-right: 2.5rem;
    // }
    &.select2-selection--single {
        padding-right: 2.5rem;
    }
    .select2-selection__rendered {
        line-height: inherit !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.input-field-wrapper {
    position: relative;
    .validate-error-alert-btn {
        position: absolute;
        right: 0.85rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        .validate-error-tooltip-wrapper {
            display: none;
            position: absolute;
            top: 100%;
            padding-top: 0.5rem;
            right: -0.75rem;
            z-index: 100;
        }
        .validate-error-tooltip {
            display: block;
            background-color: $white;
            border-radius: 0.5rem;
            box-shadow: -2px 1px 2px 0 rgba(0, 0, 0, 0.15), 0 0 7px 1px rgb(112, 112, 112, 0.19);
            padding: 0.5rem 0.95rem;
            min-width: 18rem;
            // color: $red;
            color: #E82646;
        }
        &:hover {
            .validate-error-tooltip-wrapper {
                display: inline-block;
            }
        }
    }
    .error-alert-icon {
        font-size: 1.5rem;
        color: $red;
    }
}

textarea.form-control {
    height: auto !important;
}

.row.form-row {
    margin: 0 -8px;
    > * {
        padding-left: 4px;
        padding-right: 4px;
    }
}

.dropdown-menu-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACZSURBVHgBbYs9DoJAEEa/XRWl8wgewStwBE8gpXZrYmxs7EzUxO0sbPAkHkGO4BG2IeF/YBJIWGCqyffeEzt1XZXO7DcB1Ot2/GDk9qfHtgA0UeLJGAsjQP8SFDAYk5mx876fQxnog0nSqVezsB+1MjM3jdiBaKGvnsu5k3/rdy0hfN66stYXYwX9qJkseRBYEcG4WbTpynwVT1lN926mwM8AAAAASUVORK5CYII=');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto;
}

.dropdown-menu-arrow-large {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.15rem;
    height: 1rem;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACsSURBVHgBhZAxDoIwFIZfKd6DOxgnFiaOAmwwSHFrmTSt7oZbOBMTTWTDM3gFT0DtMyHRSuUf2v9P+738eQBGCVM8rWQOM0rLfY5/0ZNssw304D8waADRyLKegjJ24BoGgd5fkID2t/NzFcbE5Mgc0TKM4d61Vxdk7vq4YyeKoe/aiwu2oUZWb0++Jyth6vKxtgceTEE/oA2PsiEUtcHP2i7or5K1KszqC9f7C/CLVsmWTyPtAAAAAElFTkSuQmCC');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto;
}

.dropdown-menu {
    border: 0;
    box-shadow: $unattached-ddown-boxes-shadow;
}

.box-nowrap {
    white-space: nowrap;
    > * {
        display: inline-block;
        white-space: normal;
    }
}

.pos-abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pos-abs-v-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.pos-abs-h-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.bg-purple {
    background-color: $purple-light;
}

.text-red {
    color: $red;
}
.text-green {
    color: $green-dark;
}
.text-blue {
    color: $blue;
}
.text-pink {
    color: $pink;
}
.text-purple {
    color: $purple;
}
.text-orange {
    color: $orange;
}
.text-gray-light {
    color: $gray-500;
}

.badge-red {
    background-color: $red-light;
    color: $red;
    border-color: $red !important;
}
.badge-green {
    background-color: $green-light;
    color: $green-dark;
    border-color: $green-dark !important;
}
.badge-blue {
    background-color: $blue-light;
    color: $blue;
    border-color: $blue !important;
}
.badge-pink {
    background-color: $pink-light;
    color: $pink;
    border-color: $pink !important;
}
.badge-purple {
    background-color: $purple-light;
    color: $purple;
    border-color: $purple !important;
}
.badge-orange {
    background-color: $orange-light;
    color: $orange;
    border-color: $orange !important;
}
.badge-gray-light {
    background-color: $gray-200;
    color: $gray-400;
    border-color: $gray-400 !important;
}
.badge-gray-extra-light {
    background-color: $gray-100;
    color: $gray-400;
    border-color: $gray-400 !important;
}

.badge {
    font-weight: 500;
    cursor: default;
}

.badge-circle {
    font-size: 0.833rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    > span {
        @extend .pos-abs-center;
    }
}

.badge-circle-small {
    @extend .badge-circle;
    font-size: 0.5rem;
    border-width: 0;
}

.badge-large {
    font-size: 0.833rem;
    line-height: 1.5;
    border-radius: 5rem;
    // padding: 5px 22px 4px 18px;
    padding: 5px 0.75rem 4px 0.75rem;
    min-width: 56px;
}

.badge-large-square {
    width: $blocks-unit-size;
    height: $blocks-unit-size;
    position: relative;
}

.badge-top-left,
.badge-top-right {
    position: absolute !important;
    top: 0 !important;
}
.badge-top-left {
    left: 0 !important;
    transform: translate(-50%, -50%);
}
.badge-top-right {
    left: 100% !important;
    transform: translate(-50%, -50%);
}

.border,
.border-bottom,
.border-top,
.border-left,
.border-right {
    border: $border-color;
}

.rounded {
    border-radius: $border-radius !important;
}

.shadow {
    box-shadow: $boxes-shadow !important;
}


.text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.number-large {
    font-size: 1.33rem;
    font-weight: 500;
    line-height: 1.5;
}


.custom-scrollbar {
    // scrollbar-color: #e1e1ee #edf2f6;
    scrollbar-color: #d2d2da #f6f6f6;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px
    }
    &::-webkit-scrollbar-track {
        // background-color: #edf2f6;
        // background-color: #f5f6f7;
        // background-color: #f6f7f8;
        // background-color: #f5f5f5;
        background-color: #f6f6f6;
        border-radius: 4px
    }
    &::-webkit-scrollbar-thumb {
        // background-color: #e1e1ee;
        background-color: #d2d2da;
        border-radius: 4px;
        border-collapse: collapse;
        box-shadow: inset 2px 2px 2px rgba(255,255,255,.2),inset -2px -2px 2px rgba(0,0,0,.08)
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #abb6c3
    }
}


.cards-wrapper {
    margin-left: -$boxes-gap-size;
    margin-right: -$boxes-gap-size;
}

.card {
    border: none;
    padding: $boxes-padding-size;
    border-radius: $card-border-radius;
    // margin-bottom: $boxes-gap-size;
    // overflow: hidden;
    box-shadow: $boxes-shadow;
    .card-title-bar {
        .btn-link {
            text-decoration: underline;
            padding: 0 0 2px;
        }
    }
    .card-title,
    .card-subtitle {
        font-weight: $box-title-fontWeight;
        line-height: $box-title-lineHeight;
        text-transform: capitalize;
        color: $gray-600;
    }
    .card-title {
        font-size: $box-title-fontSize;
    }
    .card-subtitle {
        font-size: $box-subtitle-fontSize;
    }
    .card-head-description {
        label {
            text-transform: capitalize;
        }
    }
}


// .select-modal,
// .custom-prompt-modal {
// }

.select-modal {
    .modal-body {
        padding: 1rem 0.55rem;
    }
    .modal-title {
        text-align: center;
        font-weight: 600;
        // text-transform: capitalize;
    }
    .select-modal-description {
        text-align: center;
        font-weight: 300;
        font-size: 1.12rem;
        padding: 0 1.5rem 0.75rem;
    }
    .select-options-list {
        @extend .custom-scrollbar;
    }
    .select-option-item {
        font-size: 1.075rem;
        position: relative;
        padding: 1.125rem 1rem;
        cursor: pointer;
        border-radius: 0.5rem;
        &:not(:last-child):after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0.45rem;
            width: calc(100% - 0.9rem);
            height: 1px;
            background-color: $border-color;
        }
        &:hover:not(.active) {
            background-color: $gray-100;
        }
        &.active {
            background-color: $gray-200;
        }
    }
}

.custom-prompt-modal {
    .modal-dialog {
        @include mediaBreak(576px) {
            max-width: 385px;
        }
    }
    .modal-body {
        padding: 1rem 0.55rem;
    }
    .modal-title {
        text-align: center;
        font-weight: 500;
    }
}


// .validation-text-plh {
//     min-height: calc(1.35em + 1.5rem);
// }

.validation-text {
    font-size: 0.955em;
    display: inline-block;
    margin-top: 0.5rem;
}


.cash-register-card,
.multi-step-process-box,
.payment-manage-card {
    .available-items-list,
    .added-items-list {
        padding-top: 1px;
    }
    .available-items-plhs-list,
    .added-items-plhs-list {
        .plhs-list-item {
            padding: 0 1.67rem 0 1.33rem;
        }
    }
    .available-item,
    .added-item {
        // padding: calc(1.167rem - 1px) 0.25rem calc(1.083rem - 1px);
        padding: calc(1.167rem - 1px) 1.67rem calc(1.083rem - 1px);
        border-width: 0 0 1px;
        border-style: solid;
        border-color: $gray-blue-light;
        &:nth-child(even) {
            background-color: $bg-color;
            border-bottom-color: $gray-blue-light;
        }
        .title {
            color: $primary;
            font-weight: 500;
            display: block;
            margin-bottom: 0.25rem;
            max-width: 100%;
        }
        .times-icon {
            font-size: 1.6em;
            font-weight: 300;
            line-height: 0.8;
            vertical-align: sub;
        }
        // .subset-items-list {
        // }
        .subset-item-title,
        .subset-item-price {
            color: $gray-600;
        }
        .subset-item-title {
            font-size: 1.05em;
            padding: 0 0.5rem;
        }
        .subset-item-price {
            flex-grow: 1;
            max-width: calc(100% - 20rem);
            min-width: 5rem;
            padding-left: 0.5rem;
            padding-right: 0.25rem;
        }
        .item-total-price-row {
            // width: 25rem;
            // max-width: 100%;
            max-width: 26rem;
            .price {
                flex-grow: 1;
                max-width: calc(100% - 19.75rem);
                min-width: 5rem;
                padding-left: 0.5rem;
                padding-right: 0.25rem;
            }
        }
        .controls {
            display: flex;
            align-items: center;
        }
        .item-quantity {
            margin-right: 0.67rem;
        }
        .icon-btn {
            height: 2rem !important;
            width: 2rem;
            padding: 0.25rem 0.65rem;
            border-radius: $border-radius-small;
            margin-left: 0.67rem;
            &:disabled,
            &.disabled {
                background-color: $gray-blue-light;
                color: $gray-500;
                pointer-events: none;
            }
            .icon {
                font-size: 1.25rem;
                line-height: 0;
            }
            &.item-show-btn {
                .icon {
                    font-size: 1.33rem;
                }
            }
            svg {
                vertical-align: baseline;
            }
        }
    }
}

.cash-register-card,
.payment-manage-card,
.empty-page-card {
    display: block;
    border: 1px solid $border-color;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
    .card-main-content {
        @include mediaBreak(768px) {
            float: left;
        }
        @include mediaBreak(768px, 991.98px) {
            width: calc(100% - #{$multiStepProcessBox-sm-width});
        }
        @include mediaBreak (992px) {
            width: calc(100% - #{$multiStepProcessBox-lg-width});
        }
        @include mediaBreak (1200px) {
            width: calc(100% - #{$multiStepProcessBox-xl-width});
        }
        @include mediaBreak (1350px) {
            width: calc(100% - #{$multiStepProcessBox-xxl-width});
        }
        // @include mediaBreak (1440px) {
        //     width: calc(100% - #{$multiStepProcessBox-xxl-width});
        // }
    }
}


.cash-register-card,
.payment-manage-card {
    .card-title {
        color: $primary;
    }
}


.cash-register-card {
    &.show-added-items-box {
        @include mediaBreakEndPoint(767.98px) {
            transform: translateX(0);
            margin-left: 0;
            .card-main-content {
                left: 0;
            }
        }
    }
    @include mediaBreakEndPoint(767.98px) {
        background-color: transparent;
        border: none;
        box-shadow: none;
        // transform: translateX(-100%);
        // margin-left: -9px;
        // transition: transform ease 0.275s;
        .card-main-content,
        .multi-step-process-box {
            border: 1px solid $border-color;
            border-radius: 4px;
            box-shadow: 4px 6px 12px 0 rgba(27, 33, 44, 0.05);
            background-color: $white;
            width: calc(100vw - 1.5rem);
        }
        .card-main-content {
            position: fixed;
            // left: calc(100% + 1.5rem);
            left: calc(100% + 9px);
            height: calc(100vh - 71px - 0.67rem);
            z-index: 1000;
            transition: left ease 0.275s;
        }
        .multi-step-process-box {
            // position: fixed;
            // left: calc(100% + 9px);
            // height: calc(100vh - 71px - 0.67rem);
            overflow: hidden;
            // z-index: 100;
        }
    }
}
