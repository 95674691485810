
.simple-keyboard {
    // max-width: 850px;
    // &.hg-theme-default {
    //     &.myTheme {
    //         border: 5px solid rgb(255, 0, 0, 0.7);
    //         border-radius: 10px;
    //         margin: 10px;
    //         width: calc(100% - 20px);
    //     }
    // }
    &.keyboard-disabled {
        .hg-button {
            pointer-events: none;
            opacity: 0.45;
        }
    }
    &.hg-layout-default {
        // .hg-button.hg-red {
        //     background: rgb(255, 0, 0, 0.7);
        //     color: white;
        // }
        // .hg-button.hg-highlight {
        //     box-shadow: 0px 0px 40px 5px rgba(255, 255, 0, 0.5);
        //     z-index: 1;
        // }
        &.mobile-keybord-theme {
            .hg-button {
                &.hg-button-shift {
                    width: 3.75rem;
                    margin-right: 0.35rem;
                    margin-left: 0.35rem;
                }
                &.hg-button-backspace {
                    width: 3.75rem;
                    margin-right: 0.35rem;
                }
                &.hg-button-numbers {
                    width: 6rem;
                    margin-left: 0.5rem;
                }
                &.hg-button-space {
                    width: 16rem;
                }
                &.hg-button-enter {
                    width: 6rem;
                    margin-right: 0.5rem;
                }
            }
        }
        &.simple-numbers-keyboard {
            .hg-button {
            }
        }
    }
} 
