
@import '../variables';
@import '../mixins';


.no-items-message {
    font-size: 1.167rem;
    // font-weight: 500;
    text-align: center;
    color: $gray-500;
    padding: 7vw 0;
}

.payment-manage-card {
    @include mediaBreakEndPoint(767.98px) {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .card-main-content,
    .payment-box {
        @include mediaBreakEndPoint(767.98px) {
            width: 100%;
            height: calc(100vh - 71px - 0.67rem);
            background-color: $white;
            border: 1px solid $border-color;
        }
    }
    .card-main-content {
        @include mediaBreakEndPoint(767.98px) {
            display: flex;
            flex-direction: column;
            border-radius: 4px;
        }
    }
    .payment-box-overlay {
        @include mediaBreakEndPoint(767.98px) {
            position: fixed;
            z-index: 699;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000, $alpha: 0.2);
        }
    }
    .payment-box {
        height: 100%;
        @include mediaBreakEndPoint(767.98px) {
            position: fixed;
            z-index: 700;
            left: 0;
            bottom: 0;
            width: 100vw;
            min-height: 75vh;
            height: 43rem;
            max-height: calc(100vh - 5rem);
            overflow-y: auto;
            transform: translateY(100%);
            transition: transform ease 0.25s;
            border-radius: 1rem 1rem 0 0;
            &.mobile-view-show {
                transform: translateY(0);
            }
        }
        @include mediaBreak(768px) {
            float: right;
            border-left: 1px solid $border-color;
        }
        @include mediaBreak(768px, 991.98px) {
            width: $payBoxWithKeyboard-sm-width;
        }
        @include mediaBreak (992px, 1199.98px) {
            width: $payBoxWithKeyboard-lg-width;
        }
        @include mediaBreak (1200px, 1349.98px) {
            width: $payBoxWithKeyboard-xl-width;
        }
        .input-fields-box {
            @include mediaBreak(992px) {
                width: 50%;
            }
        }
        .validation-text-plh {
            position: absolute;
            left: 0.55rem;
        }
        .total-price-row {
            label {
                // color: #9d9d9d;
                font-size: 1.2rem;
            }
        }
        .btn-list-box {
            display: flex;
            .btn {
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                min-width: 3rem;
                height: calc(1.875em + 1.8rem + 2px);
            }
        }
        table {
            vertical-align: middle;
        }
        tbody {
            white-space: nowrap;
        }
        .main-btns {
            margin: 0 -0.5rem;
            .btn {
                width: calc(100% - 1rem);
                margin: 0 0.5rem;
            }
            .btn-icon {
                font-size: 2em;
                display: inline-block;
                vertical-align: sub;
                // color: $gray-400;
                line-height: 1.35;
            }
            .btn-text {
                display: inline-block;
                width: calc(100% - 2em - 0.5rem);
                padding-right: 0.5rem;
            }
            @include mediaBreak(576px, 767.98px) {
                display: flex;
                .btn {
                    width: calc(50% - 1rem);
                }
            }
            @include mediaBreak(1200px) {
                display: flex;
                .btn {
                    width: calc(50% - 1rem);
                }
            }
        }
    }
    &.with-static-keyboard {
        .card-main-content {
            @include mediaBreak(768px, 991.98px) {
                width: calc(100% - #{$payBoxWithKeyboard-sm-width});
            }
            @include mediaBreak (992px) {
                width: calc(100% - #{$payBoxWithKeyboard-lg-width});
            }
            @include mediaBreak (1200px) {
                width: calc(100% - #{$payBoxWithKeyboard-xl-width});
            }
        }
        .payment-box {
            @include mediaBreak(768px, 991.98px) {
                width: $payBoxWithKeyboard-sm-width;
            }
            @include mediaBreak(992px, 1199.98px) {
                width: $payBoxWithKeyboard-lg-width;
            }
            @include mediaBreak(1200px) {
                width: $payBoxWithKeyboard-xl-width;
            }
        }
    }
    .payment-numbers-keyboard {
        // min-width: 165px;
        // width: 175px;
        width: 100%;
        max-width: 425px;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
        .hg-button {
            font-size: 1.5rem;
            font-weight: 600;
            // width: 33.33%;
            width: calc(18% - 5px);
            max-width: calc(18% - 5px);
            height: 40px;
            @include mediaBreak(992px) {
                width: calc(15% - 5px);
                max-width: calc(15% - 5px);
            }
        }
        .hg-button-payByCash,
        .hg-button-payByCreditCard,
        .hg-button-clear {
            height: 85px;
            margin-bottom: -45px;
            text-align: center;
        }
        .hg-button-payByCash {
            max-width: 28%;
            @include mediaBreak(992px) {
                max-width: 40%;
            }
        }
        .hg-button-payByCreditCard {
            // max-width: 46%;
            // @include mediaBreak(992px) {
            //     max-width: 55%;
            // }
            max-width: 28%;
            @include mediaBreak(992px) {
                max-width: 40%;
            }
        }
        .hg-button-clear {
            font-size: 1.2em;
            // font-weight: 600;
        }
        &.flat {
            background-color: transparent;
            padding: 0;
            border-color: $border-color;
            border-style: solid;
            border-width: 0 0 1px 1px;
            .hg-row {
                margin: 0;
            }
            .hg-button {
                border-color: $border-color;
                border-style: solid;
                border-width: 1px 1px 0 0;
                border-radius: 0;
                margin: 0;
                box-shadow: none;
            }
        }
        &.dark-keyboard {
            background-color: transparent;
            .hg-button {
                box-shadow: none;
            }
            .hg-button:not(.hg-activeButton):not(.hg-button-payByCash):not(.hg-button-payByCreditCard):not(.hg-button-arrowup):not(.hg-button-arrowdown) {
                background-color: #161E28;
                border-color: #161E28;
                color: #E8E8F7;
            }
            .hg-activeButton {
                background-color: #73738b;
                border-color: #73738b;
                color: #fff;
            }
            .hg-button-payByCash,
            .hg-button-payByCreditCard {
                // background-color: #05C3FB;
                // border-color: #05C3FB;
                background-color: #198754;
                border-color: #198754;
                color: $white;
            }
            .hg-button-arrowup,
            .hg-button-arrowdown {
                background-color: #ffc107;
                border-color: #ffc107;
            }
        }
        &.gray-keyboard {
            // background-color: #dcdcdc;
            background-color: transparent;
            .hg-button {
                box-shadow: none;
            }
            .hg-button:not(.hg-activeButton):not(.hg-button-payByCash):not(.hg-button-payByCreditCard):not(.hg-button-arrowup):not(.hg-button-arrowdown):not(.hg-button-clear) {
                background-color: #efeeea;
                border-color: #efeeea;
                color: #161E28;
            }
            .hg-activeButton {
                background-color: #dbd9d1;
                border-color: #dbd9d1;
                color: #161E28;
            }
            .hg-button-payByCash,
            .hg-button-payByCreditCard {
                font-size: 1.25rem;
                background-color: #2bbb78;
                border-color: #2bbb78;
                color: $white;
                &.hg-activeButton {
                    background-color: #19af69;
                    border-color: #19af69;
                }
            }
            .hg-button-arrowup,
            .hg-button-arrowdown {
                background-color: #ced2d5;
                border-color: #ced2d5;
                &.hg-activeButton {
                    background-color: #bbc0c2;
                    border-color: #bbc0c2;
                }
            }
            .hg-button-clear {
                background-color: #d4dadf;
                border-color: #d4dadf;
                &.hg-activeButton {
                    background-color: #ccd0d3;
                    border-color: #ccd0d3;
                }
            }
        }
    }
    .payment-items {
        max-height: calc(100vh - 12.5rem - 56px);
        overflow-y: auto;
        margin-bottom: 0.25rem;
        @include mediaBreakEndPoint(767.98px) {
            margin-bottom: 1rem;
            max-height: calc(100vh - 16.5rem - 56px);
        }
    }
    .added-item {
        .image {
            img {
                min-width: 3.5rem;
                max-width: 3.5rem;
                min-height: 3.5rem;
                max-height: 3.5rem;
                border-radius: $card-border-radius;
            }
        }
    }
}

.payment-numbers-keyboard {
    &.clear-btn-is-disabled {
        .hg-button-clear {
            pointer-events: none;
            opacity: 0.45;
        }
    }
    &.keyboard-disabled {
        &:not(.pay-btns-are-disabled) {
            .hg-button {
                &.hg-button-payByCash,
                &.hg-button-payByCreditCard {
                    pointer-events: all;
                    opacity: 1;
                }
            }
        }
    }
    &.pay-btns-are-disabled {
        .hg-button {
            &.hg-button-payByCash,
            &.hg-button-payByCreditCard {
                pointer-events: none;
                opacity: 0.45;
            }
        }
    }
}

.payment-box-body {
    position: relative;
    display: flex;
    flex-direction: column;
}
.payment-btns-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    .btn {
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.65;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        min-height: 4.5rem;
        &:not(:last-child) {
            margin-right: 0.5rem;
        }
        &:not(:last-child) {
            margin-left: 0.5rem;
        }
        .icon {
            font-size: 1.35em;
            display: inline-block;
            vertical-align: middle;
            margin-top: -0.15em;
        }
        svg {
            display: block;
        }
        // .btn-text {
        //     display: inline-block;
        //     margin-top: 0.1em;
        // }
    }
}

.open-payments-modal {
    .modal-dialog {
        @include mediaBreak(768px, 991.98px) {
            max-width: 750px;
        }
        @include mediaBreak(992px, 1199.98px) {
            max-width: 920px;
        }
        @include mediaBreak(1200px) {
            max-width: 1176px;
        }
    }
    // .choose-seller-filter-select {
    // }
    .seller-item-sec-heading-label {
        font-size: 1.1rem;
    }
    .seller-item-sec-title {
        font-size: 1.25rem;
    }
    .open-payments-table {
        max-width: 100%;
        th {
            font-weight: 500;
        }
        td {
            font-size: 1.3rem;
        }
    }
    // .charts-wrapper {
    //     @include mediaBreakEndPoint(575.98px) {
    //         overflow-y: auto;
    //     }
    // }
    .chart-item-wrapper {
        width: 350px;
        @include mediaBreak(576px) {
            width: 460px;
        }
        @include mediaBreak(768px) {
            width: 575px;
        }
    }
}

